@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../_styles/variables.less';

.addressbook__contact__wrapper {
  padding: 20px;

  .addressbook__contact__header-info {
    padding: 5px 0;
    gap: 10px;
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    overflow: hidden;
  }

  .addressbook__contact__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    font-family: 'Manrope', serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: @carbone;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .addressbook__contact__name-email {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;

      color: @darkCloudGray;
    }
  }

  .addressbook__contact__content {
    display: grid;
    grid-template-columns: 140px 1fr;

    margin-bottom: 20px;

    font-family: 'Manrope', serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .addressbook__contact__title {
      color: @darkCloudGray;
    }
  }

  .addressbook__contact__controls {
    display: flex;
    gap: 10px;

    .addressbook__contact__control {
      padding: 0;

      & > span {
        padding: 0;
        height: 20px;
      }

      svg {
        path {
          fill: @carbone;
        }
      }

      &:hover {
        svg {
          path {
            fill: @ramblerBlue;
          }
        }
      }

      &:active {
        svg {
          path {
            fill: @darkBlue;
          }
        }
      }
    }

    .addressbook__contact__control__add-event {
      padding: 20px;
    }
  }
}
