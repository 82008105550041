@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.alarm__notification {
  display: flex;
  flex-direction: row;
  position: relative;

  width: 300px;

  margin-left: 10px;
  padding: 20px 20px 20px 15px;
  background: @carbone;

  border-radius: 15px;

  .alarm__marker {
    position: absolute;
    top: 0;
    left: -10px;
    user-select: none;
  }

  .notification__blue {
    width: 10px;
    height: 10px;

    margin-top: 5px;

    border-radius: 50%;
    background: @alternativeBlue6;
  }

  .notification__content {
    flex: 1;
    margin: 0 20px 0 15px;

    overflow: hidden;

    color: @alternativeBlue1;

    font-family: Manrope, serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;

    div {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .notification__content__summary {
      margin-bottom: 15px;
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Manrope, serif;
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
    }

    .notification__content__info {


      .notification__content__info__more {
        display: inline-block;

        cursor: pointer;
        user-select: none;

        color: @cloudGray;

        &:hover {
          color: white;
        }
      }
    }

    .notification__content__later {
      margin-top: 20px;

      cursor: pointer;
      user-select: none;

      color: @alternativeBlue6;
    }
  }

  .notification__close-btn {
    height: 20px;
    cursor: pointer;

    svg {
      path {
        fill: white !important;
      }
    }

    &:hover {
      svg {
        path {
          fill: rgba(255, 255, 255, 0.7) !important;
        }
      }
    }
    &:active {
      svg {
        path {
          fill: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }
}
