@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-room {
  margin-top: 20px;
  position: relative;
}

.event-create-room-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: @alternativeBlue1;
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  cursor: pointer;

  &.selected {
    span {
      color: @darkCarbone;
    }
  }

  span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCloudGray;
    user-select: none;
  }
}

.event-create-room-select-item {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: @darkCarbone;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    color: @ramblerBlue;
  }

  &.selected {
    color: @ramblerBlue;
  }
}
