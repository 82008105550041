@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.contacts-list {
  overflow-x: hidden;

  .header {
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0 20px;

    border-bottom: 1px solid @alternativeBlue4;

    .toggleContactGroups {
      & > button {
        height: 30px;
        padding: 5px 20px;
      }
    }

    .contactSwitcherWrapper {
      height: 20px;
    }
  }

  .group {
    display: flex;
  }

  .group-letter {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    width: 30px;
    color: @carbone;
  }

  .group-contacts {
    flex: 1;
    overflow: hidden;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      background-color: @alternativeBlue2;

      .checked {
        span > span {
          background-color: var(--rc-checkbox-checked-mark-hover-bgColor);
        }
      }

      .checkbox:not(.checked) {
        span > span {
          border: solid 1px var(--rc-checkbox-border-mark-hover-borderColor);
        }
      }
    }

    &:active {
      background-color: @alternativeBlue3;


      .checked {
        span > span {
          background-color: var(--rc-checkbox-checked-mark-active-bgColor);
        }
      }

      .checkbox:not(.checked) {
        span > span {
          border: solid 1px var(--rc-checkbox-border-mark-active-borderColor);
        }
      }
    }
  }

  .contact {
    position: relative;
    flex: 1;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .contact-info {
      display: block;
      overflow: hidden;

      font-family: 'Manrope', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      margin-right: 5px;
      color: @carbone;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .fullName {
      margin-right: 5px;
    }

    .contact-email {
      color: @darkCloudGray;
    }

    .control {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-left: 20px;
    }
  }
}
