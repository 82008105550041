@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../_styles/variables.less';

.addressbook__mailing-group__wrapper {
  padding: 20px;

  .addressbook__mailing-group__header-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    min-height: 110px;

    margin-bottom: 40px;

    font-family: 'Manrope', serif;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;
  }

  .addressbook__mailing-group__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    font-family: 'Manrope', serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: @carbone;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .addressbook__mailing-group__name-email {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;

      color: @darkCloudGray;
    }
  }

  .addressbook__mailing-group__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    a:link { text-decoration: none; }
    a:visited { text-decoration: none; }
    a:hover { text-decoration: none; }
    a:active { text-decoration: none; }

    .addressbook__mailing-group__control {
      padding: 20px;
      white-space: nowrap;
      overflow: hidden;

      & > span {
        padding: 0;
        height: 20px;
      }

      svg {
        path {
          fill: @carbone;
        }
      }

      &:hover {
        svg {
          path {
            fill: @ramblerBlue;
          }
        }
      }

      &:active {
        svg {
          path {
            fill: @darkBlue;
          }
        }
      }
    }
  }
}
