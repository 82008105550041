@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.notifier-notifications {
  z-index: 902;
  position: fixed;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 10px;
}
