@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../_styles/variables.less';

.contact-overview__root {
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'aw dn'
    '.  jt';

  position: relative;

  margin-left: 30px;
  padding: 10px 10px 10px 0;
  border-radius: 5px;


  &:hover {
    .contact-overview__display-name, .contact-overview__email {
      color: @ramblerBlue;
    }
  }

  &:active {
    .contact-overview__display-name, .contact-overview__email {
      color: @darkBlue;
    }
  }

  &.active {
    background-color: @alternativeBlue1;

    .contact-overview__display-name {
      color: @ramblerBlue;

      .contact-overview__email {
        color: @ramblerBlue;
      }
    }
  }

  .contact-overview__letter_group {
    position: absolute;
    left: -30px;
    top: 15px;

    width: 20px;
    padding-right: 10px;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    color: @darkCarbone;
  }

  .contact-overview__avatar_wrapper {
    grid-area: aw;

    padding-left: 10px;
    padding-right: 10px;
  }

  .contact-overview__display-name {
    grid-area: dn;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .contact-overview__email {
    padding-left: 5px;
    color: @darkCloudGray;
  }

  .contact-overview__job-tittle {
    grid-area: jt;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    color: @darkCloudGray;
  }
}
