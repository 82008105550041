@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.snackbar-notifier {
  z-index: 902;
  position: fixed;
  bottom: 20px;
  left: 20px;

  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 10px;
}
