@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.progressbar-progress {
  width: 100%;
  height: 5px;
  max-width: 100px;
  background-color: white;
  border-radius: 5px;
}

.progressbar-loaded {
  width: 0;
  background-color: #456EFB;
  height: 5px;
  border-radius: 5px;
}
