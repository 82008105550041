@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.multiselect-wrapper {
  width: 100%;
  max-width: 305px;
}

.closeIcon {
  svg {
    path {
      fill: white;
    }
  }

  &:hover {
    svg {
      path {
        fill: @alternativeBlue2;
      }
    }
  }

  &:active {
    svg {
      path {
        fill: @alternativeBlue3;
      }
    }
  }
}

.multiselect {
  padding: 5px 10px 5px 5px;
  background-color: @alternativeBlue1;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: background-color 200ms;
  -webkit-user-select: none; /* Safari fallback only */
  user-select: none;
  width: 100%;

  &.error {
    background-color: @red1 !important;

    &&:hover {
      background-color: @red2;
    }
  }

  &&.focused {
    background-color: @alternativeBlue2;
  }

  &&:hover {
    background-color: @alternativeBlue2;
  }

  .multiselect-placeholder {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px !important;
    color: @carbone;
    margin-left: 10px;
  }
}

.multiselect-dropdown {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 45px;
  background-color: @alternativeBlue1;
  border-radius: 5px;
  box-shadow: 0px 20px 20px -10px rgba(49, 94, 251, 0.3);
  visibility: hidden;
  z-index: @multiSelectDropdownZIndex;
  width: 100%;

  .checkbox {
    width: 100%;
    padding: 10px;

    transition: background-color 200ms, color 200ms;
    * {
      transition: background-color 200ms, color 200ms;
    }

    &:hover {
      background-color: @alternativeBlue2;
      span {
        color: @middleBlue !important;
      }
    }

    &:active {
      background-color: @alternativeBlue3;
      span {
        color: @darkBlue !important;
      }
    }
  }

  &.opened {
    visibility: visible;
  }

  & > div {
    width: 100%;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > div > p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;
    margin: 0;
  }

  & > div > div {
    width: 30px;
  }
}

.multiselect-selected-values-block {
  display: flex;
  gap: 5px;

  .multiselect-selected-value {
    display: flex;
    background-color: @darkAlternativeBlue;
    transition: background-color .3s;
    border-radius: 25px;
    padding: 5px 5px 5px 10px;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    &:last-child {
      margin-right: 0px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: @middleBlue;
    }

    &:active:not(:has(.closeIcon:hover, .closeIcon:active)) {
      background-color: @darkBlue;
    }
  }
}

.multiselect-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  path {
    fill: @carbone;
  }
}

@media screen and (max-width: 380px) {
  .multiselect-selected-values-block {
    width: 100%;
    flex-wrap: wrap;
  }

  .multiselect {
    align-items: flex-start;
  }
}
