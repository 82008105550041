@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.month-item-event {
  padding: 5px;
  border-radius: 5px;
  height: 24px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  &.shadow {
    background-color: unset !important;
    border: 1px solid @cloudGray3;
    span {
      color: @cloudGray7 !important;
    }

    &:hover {
      border: 1px solid @cloudGray4;
    }

    &:active, &.viewing {
      border: 1px solid @cloudGray5;
      span {
        color: @cloudGray !important;
      }
    }

  }

  &.partstat-need-action {
    span {
      font-weight: 700 !important;
    }
  }

  &.regular {
    &:hover {
      background-color: @aquamarine1;
    }

    &:active, &.viewing {
      background-color: @aquamarine2;
    }
  }

  &.regular.past {
    &:hover {
      background-color: @cloudGray05;
    }

    &:active, &.viewing {
      background-color: @cloudGray1;
      span {
        color: @cloudGray !important;
      }
    }
  }

  &.regular.cancelled {
    &:hover {
      background-color: @cloudGray05;
    }

    &:active, &.viewing {
      background-color: @cloudGray1;
      span {
        color: @cloudGray !important;
      }
    }
  }

  &.whole-day-partstat-need-action {
    border: 1px solid @aquamarine;
    border-radius: 5px;
    padding: 4.5px 0 4.5px 5px;
    box-shadow: 0px 10px 10px -10px rgba(0, 92, 86, 0.4);

    &:hover {
      border: 1px solid @middleAquamarine;

      span {
        color: @middleAquamarine;
      }

      path {
        fill: @middleAquamarine;
      }
    }

    &:active, &.viewing {
      border: 1px solid @darkAquamarine;

      span {
        color: @darkAquamarine !important;
      }

      path {
        fill: @darkAquamarine;
      }
    }

    path {
      fill: @aquamarine;
    }
  }

  &.whole-day-partstat-need-action.past {
    border: 1px solid @aquamarine3;
    box-shadow: unset;

    span {
      color: @cloudGray7 !important;
    }

    path {
      fill: @aquamarine3;
    }

    &:hover {
      border: 1px solid @aquamarine4;

      span {
        color: @aquamarine4 !important;
      }

      path {
        fill: @aquamarine4;
      }
    }

    &:active, &.viewing {
      border: 1px solid @aquamarine5;

      span {
        color: @aquamarine5 !important;
      }

      path {
        fill: @aquamarine5;
      }
    }

  }

  &.tentative.past {
    svg {
      path {
        fill: @cloudGray4;
      }
    }
  }

  &.whole-day {
    border-radius: 5px;
    background-color: @aquamarine;
    padding: 4.5px 0 4.5px 5px;
    margin-bottom: 1px;
    span {
      color: white !important;
      white-space: nowrap;
    }
    box-shadow: 0px 10px 10px -10px rgba(0, 92, 86, 0.4);

    &:hover {
      background-color: @middleAquamarine;
    }

    &:active, &.viewing {
      background-color: @darkAquamarine;
    }
  }

  &.whole-day.past {
    background-color: @cloudGray05;
    box-shadow: none;

    span {
      color: @cloudGray7 !important;
    }

    &:hover {
      background-color: @cloudGray2;
    }

    &:active, &.viewing {
      background-color: @cloudGray3;
      span {
        color: @cloudGray !important;
      }
    }
  }

  &.whole-day.cancelled {
    background-color: @cloudGray05;
    box-shadow: none;

    &:hover {
      background-color: @cloudGray2;
    }

    &:active, &.viewing {
      background-color: @cloudGray3;
      color: @cloudGray !important;
    }
  }

  &.cancelled {
    text-decoration: line-through;
    color: @cloudGray7 !important;

    span {
      color: @cloudGray7 !important;
    }
  }

  &.past {
    span {
      color: @cloudGray7 !important;
    }
  }

  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    white-space: nowrap;

    &.month-item-event-time {
      width: 35px;
      color: @darkCloudGray;
    }

    &.month-item-event-title {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @darkCarbone;
      overflow: hidden;
    }
  }

  .month-item-event-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;

    svg {
      width: 15px;
      height: 15px;
    }

    &.white-icon {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}
