@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.wrapper-block {
  display: flex;
  align-self: stretch;
  min-width: 1000px;
  height: 100%;
  padding-bottom: 10px;

  .wrapped-content {
    background: @alternativeBlue1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 10px 0 10px;
    flex: 1;
    height: calc(100vh - @TOPLINE_HEIGHT);
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: @contentWrapperZIndex;
  }
}

@media only screen and (max-width: @tablet-max) {
  .wrapper-block {
    min-width: 0;

    .wrapped-content {
      padding: 0 10px 0 0;
    }
  }
}
