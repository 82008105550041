@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../_styles/variables.less';

.addressbook__contacts {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 615px;


  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .addressbook__contacts__no-found {
    padding-top: 10px;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;

    color: @darkCarbone;
  }
}
