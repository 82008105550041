@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.new-event-notification {
  width: 240px;
  padding: 10px;
  border: 1px solid @aquamarine;
  box-shadow: 0 10px 10px -10px rgba(49, 94, 251, 0.4), inset 0px 5px 0px @aquamarine;
  border-radius: 5px;
  background: #FFFFFF;
  overflow-x: hidden;

  &.cancelled {
    border: 1px solid @cloudGray7;
    box-shadow: 0 10px 10px -10px rgba(49, 94, 251, 0.4), inset 0px 5px 0px @cloudGray7;
  }

  .new-event__controls {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;

    .new-event__delete-button {
      background-color: @alternativeBlue2 !important;
      color: @carbone;

      &:hover {
        color: @ramblerBlue;
      }

      &:active {
        color: @darkBlue;
      }
    }

    .new-event__control {
      background-color: @alternativeBlue2 !important;

      svg {
        fill: @carbone !important;
      }
    }
  }
}
