@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.events-dropdown {
  position: absolute;
  top: 30px;
  padding: 20px;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  background-color: @alternativeBlue1;
  display: none;


  box-shadow: 0px 20px 20px -10px rgba(49, 94, 251, 0.3);
  border-radius: 10px;

  &.opened {
    display: block;
  }

  div {
    display: flex;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }

    span {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &:nth-child(1) {
        color: @darkCloudGray;
        margin-right: 5px;
      }
      &:nth-child(2) {
        color: @darkCarbone;
        flex: 1;
      }
    }
  }
}
