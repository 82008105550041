@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.day-week-event-more {
  grid-row: 1;
  padding: 0 5px;
  position: relative;
  height: 35px;

  > span {
    cursor: pointer;
    user-select: none;
    color: @darkCloudGray;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: @middleBlue;
    }

    &:active {
      color: @darkBlue;
    }

    &.opened {
      color: @ramblerBlue;
    }
  }
}
