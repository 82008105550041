@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-reminder {
  margin-top: 20px;
  position: relative;
}

.event-create-reminder-dropdown {
  width: 100%;
}

.event-create-reminder-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: @alternativeBlue1;
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  transition: background-color 200ms;

  span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;
    user-select: none;
  }

  &:hover {
    background-color: @alternativeBlue2;

    span {
      color: @middleBlue;
    }
    path {
      fill: @darkBlue;
    }
  }

  &:active {
    background-color: @alternativeBlue3;

    span {
      color: @darkBlue;
    }
    path {
      fill: @darkBlue;
    }
  }
}
