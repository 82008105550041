@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.time-divider {
  height: 0;
  width: calc(100% - 20px);
  border-bottom: 1px solid @cherry;
  position: absolute;
  z-index: @timeDividerZIndex;

  &.full-width {
    width: 100%;
  }

  .time-divider-value {
    background-color: @cherry;
    border-radius: 10px;

    margin-left: 10px;
    margin-top: -10px;

    width: 50px;

    text-align: center;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: white;
  }
}

@media only screen and (max-width: @tablet-max) {
  .time-divider {
    width: calc(100% - 10px);
    .time-divider-value {
      margin-left: 5px;
      width: 40px;
    }
  }
}
