/* Regular */
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('https://static.rambler.ru/fonts/Montserrat/regular-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('https://static.rambler.ru/fonts/Montserrat/regular-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Medium */
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('https://static.rambler.ru/fonts/Montserrat/medium-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('https://static.rambler.ru/fonts/Montserrat/medium-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Semibold /*
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('https://static.rambler.ru/fonts/Montserrat/semibold-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('https://static.rambler.ru/fonts/Montserrat/semibold-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Bold */
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('https://static.rambler.ru/fonts/Montserrat/bold-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('https://static.rambler.ru/fonts/Montserrat/bold-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Black */
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'), url('https://static.rambler.ru/fonts/Montserrat/black-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'), url('https://static.rambler.ru/fonts/Montserrat/black-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: local("Manrope"),
    url("https://static.rambler.ru/fonts/Manrope/regular.woff2") format("woff2");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: local("Manrope"),
    url("https://static.rambler.ru/fonts/Manrope/medium.woff2") format("woff2");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: local("Manrope"),
    url("https://static.rambler.ru/fonts/Manrope/semibold.woff2") format("woff2");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: local("Manrope"),
    url("https://static.rambler.ru/fonts/Manrope/bold.woff2") format("woff2");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: local("Manrope"),
    url("https://static.rambler.ru/fonts/Manrope/extrabold.woff2") format("woff2");
}

/* Set up Manrope for modern browsers, all weights */
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400 800;
    font-stretch: normal;
    src: local("Manrope"),
      url("https://static.rambler.ru/fonts/Manrope/variable.woff2") format('woff2 supports variations'),
      url("https://static.rambler.ru/fonts/Manrope/variable.woff2") format('woff2-variations');
  }
}