@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.calendar-month {
  width: 100%;
  height: calc(100% - 70px);
  display: grid;
  grid-template-columns: repeat(7, minmax(95px, calc(100% / 7)));

  background-color: white;

  box-shadow: 0px 10px 10px -10px rgba(49, 94, 251, 0.4);
  border-radius: 10px;

  &.six-rows {
    grid-template-rows: repeat(6, calc(100% / 6));
    .month-item {
      &:nth-child(36), &:nth-child(37), &:nth-child(38), &:nth-child(39), &:nth-child(40), &:nth-child(41), &:nth-child(42) {
        border-bottom: none !important;
      }
    }
  }

  &.five-rows {
    grid-template-rows: repeat(5, calc(100% / 5));
    .month-item {
      &:nth-child(29), &:nth-child(30), &:nth-child(31), &:nth-child(32), &:nth-child(33), &:nth-child(34), &:nth-child(35) {
        border-bottom: none !important;
      }
    }
  }

  &.four-rows {
    grid-template-rows: repeat(4, calc(100% / 4));
    .month-item {
      &:nth-child(22), &:nth-child(23), &:nth-child(24), &:nth-child(25), &:nth-child(26), &:nth-child(27), &:nth-child(28) {
        border-bottom: none !important;
      }
    }
  }


  .month-item {
    padding: 5px 5px 15px 5px;
    border-right: 1px solid @cloudGray2 !important;
    border-bottom: 1px solid @cloudGray2 !important;

    &.active {
      box-shadow: inset 0 0 0 2px @alternativeBlue;
      .month-item-header {
        span {
          &:nth-child(2) {
            color: @ramblerBlue;
            font-weight: 700;
          }
        }
      }
    }

    &.not-cur-month {
      .month-item-header {
        span {
          &:nth-child(2) {
            color: @darkCloudGray;
          }
        }
      }
    }

    &.topLeft {
      border-top-left-radius: 10px;
    }
    &.topRight {
      border-top-right-radius: 10px;
    }
    &.bottomLeft {
      border-bottom-left-radius: 10px;
    }
    &.bottomRight {
      border-bottom-right-radius: 10px;
    }

    &:nth-child(7n) {
      border-right: none !important;
    }

    p {
      margin: 0;
      padding: 0;
      color: @darkCarbone;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }

    .month-item-header {
      padding-left: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      display: flex;
      user-select: none;
      p {
        line-height: 15px;
      }

      div {
        flex: 1;
      }

      span {
        &:nth-child(2) {
          font-weight: 400;
          color: @darkCarbone;
        }
        font-weight: 700;
        font-family: 'Manrope';
        font-style: normal;
        font-size: 13px;
        color: @darkCloudGray;
      }
    }
  }
}

@media @lessThanMinDisplayHeight {
  .calendar-month {
    height: auto;
    margin-bottom: 10px;
  }
}
