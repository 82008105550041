@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.icon-button {
  &.active {
    svg {
      path {
        fill: @darkBlue;
      }
    }
  }
}

.icon-button:hover {
  svg {
    path {
      fill: @ramblerBlue;
    }
  }
}

.icon-button:active {
  svg {
    path {
      fill: @darkBlue;
    }
  }
}
