@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-view-window {
  position: absolute;
  z-index: @eventViewWindowZIndex;
  visibility: hidden;

  &.opened {
    visibility: visible;
  }
}
