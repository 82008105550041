@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1px;

  position: relative;
}

.row {
  height: 60px;
  border-bottom: 1px solid @cloudGray2;

  &.hovered {
    background-color: @cloudGray05;
  }
}

.cols {
  display: flex;
  flex-direction: row;

  user-select: none;

  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  left: 0;

  .col {
    min-width: 1px;
    margin-left: 59px;
    height: 100%;
    background: @cloudGray2;
  }
}
