@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.new-event-notifications {
  display: flex;
  flex-direction: column;

  padding: 20px 0 20px;
  gap: 10px;

  .new-event-notifications__title {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: @carbone;
  }
}

@media only screen and (max-width: @tablet-max) {
  .new-event-notifications {
    padding-bottom: 55px;
  }
}
