@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.day-events-wrapper {
  // width: calc(100% - 80px);
  height: 0;
  display: grid;
  margin-left: 70px;
  grid-template-columns: repeat(60, minmax(2px, 1fr));
}

@media only screen and (max-width: @tablet-max) {
  .day-events-wrapper {
    margin-left: 40px;
  }
}
