@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.mockBlock {
  width: 600px;
  height: 405px;
}

.event-create-edit-block {
  overflow-x: visible !important;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0 10px 30px rgba(49, 94, 251, 0.2);
  border-radius: 15px;
  width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.blockComparing {
  max-width: 1300px;
  width: calc(100vw - 100px);
}

.event-create-edit-block-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 0 20px;
  flex: 1;
  overflow-y: auto;
}

.comparingFields {
}

.event-create-edit-block-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5px;

  &.comparing {
    h3 {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
    }
  }

  h3 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: @carbone;
    margin: 0;
  }
}

.event-create-edit-block-recurrence-ask {
  background-color: @sunYellow;
  padding: 10px 20px 15px;
  border-radius: 5px;
  margin-bottom: 20px;

  p {
    margin: 0;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;

    &:nth-child(1) {
      color: @carbone;
    }
    &:nth-child(2), &:nth-child(3) {
      color: @ramblerBlue;
      user-select: none;
      &:hover {
        color: @middleBlue;
      }
      &:active {
        color: @darkBlue;
      }
    }
  }
}


@media only screen and (max-width: @mobile-max) {
  .event-create-edit-block {
    width: 100vw;
    border-radius: 0;
  }

  .blockComparing {
    max-width: unset;
    width: unset;
  }

  .root {
    background: white;
  }
}
