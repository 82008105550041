@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../_styles/variables.less';

.search-input {
  display: flex;

  height: 40px;
  width: 100%;

  background-color: @alternativeBlue1;
  border-radius: 5px;

  & > * {
    box-sizing: border-box;
  }

  .search-input__input {
    position: relative;
    display: flex;
    flex: 1;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    input {
      padding-right: 70px;
    }
  }

  .search-input__input__clear {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
  }

  .search-input__button {
    height: 30px;
    width: 30px;
    min-width: 30px;

    margin: 5px;
    padding: 5px;

    background-color: @ramblerBlue;
    border-radius: 5px;

    span {
      max-height: 20px;
    }

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}
