@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.new-event__block {
  cursor: pointer;

  &.active {
    &:hover {
      .new-event__summary {
        color: @middleBlue !important;
      }
    }

    &:active {
      .new-event__summary {
        color: @darkBlue;
      }
    }
  }

  &.cancelled {
    .new-event__summary {
      color: @cloudGray7;
      text-decoration: line-through;

      &.selected {
        color: @cloudGray7 !important;
      }
    }

    .new-event__date {
      color: @cloudGray7;
    }

    .new-event__location {
      color: @cloudGray7;
    }
  }
}

.new-event__summary {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: @darkCarbone;

  overflow: hidden;
  text-overflow: ellipsis;

  &.selected {
    color: @darkBlue;
  }
}

.new-event__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: @darkCarbone;
}

.new-event__location {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: @darkCarbone;

  overflow: hidden;
  text-overflow: ellipsis;
}
