@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.root {
  z-index: -100;

  opacity: 0;

  transition: opacity 0.1s ease-in-out;
}

.open {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10000;

  opacity: 1;
}
