@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-description__wrapper {
  textarea {
    &::-webkit-scrollbar {
      border: none;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      border: none;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: none;
      box-shadow: none;
      background-color: @alternativeBlue6;
      border-radius: 10px;
    }
  }
}
