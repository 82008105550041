@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../../_styles/variables.less';

.contact-overview__avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  border-radius: 15px;

  background-size: cover;
  color: white;

  font-family: 'Manrope', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 10px;
}
