@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.titleAndRange {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}


.event-create-button-blue {
  display: flex;
  font-weight: 600;
  line-height: 20px;
  font-size: 15px;
  font-family: 'Manrope';
  align-items: center;
  color: @ramblerBlue;
  font-style: normal;
  cursor: pointer;
  user-select: none;

  svg {
    margin-right: 5px;
    path {
      fill: @ramblerBlue;
    }
  }

  &:hover {
    color: @middleBlue;

    path {
      fill: @middleBlue;
    }
  }

  &:active {
    color: @darkBlue;

    path {
      fill: @darkBlue;
    }
  }
}
