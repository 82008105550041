@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.week-event {
  box-shadow: 0px 2px 0px 0px @aquamarine inset;
  height: 100%;
  padding: 5px 5px 0;
  overflow: hidden;

  &.small-block {
    padding: 0px 5px 0px 5px;
    p {
      line-height: 15px;
      vertical-align: middle;
      height: 15px !important;
    }
  }

  &.less-30min {
    .week-event-title-wrapper {
      svg {
        margin-right: 3px;
        width: 10px;
        height: 10px;
      }
    }
  }

  &.day {
    display: flex;
  }

  &.cancelled {
    box-shadow: 0px 2px 0px 0px @cloudGray7 inset;
    background-color: @cloudGray1 !important;
    p {color: @cloudGray7 !important;}
    span {color: @cloudGray7 !important;}

    .week-event-title-wrapper span {
      text-decoration: line-through;
    }

    &:hover {
      background-color: @cloudGray2 !important;
    }

    &:active, &.viewing {
      background-color: @cloudGray3 !important;

      p {color: @cloudGray !important;}
      span {color: @cloudGray !important;}
    }
  }

  &.shadow {
    box-shadow: 0px 2px 0px 0px @cloudGray7 inset !important;
    border-left: 1px solid @cloudGray3;
    border-right: 1px solid @cloudGray3;
    border-bottom: 1px solid @cloudGray3;

    span {
      color: @cloudGray7;
      line-height: 15px;
    }

    &:hover {
      border-left: 1px solid @cloudGray4;
      border-right: 1px solid @cloudGray4;
      border-bottom: 1px solid @cloudGray4;
    }

    &:active {
      box-shadow: 0px 2px 0px 0px @cloudGray inset !important;
      border-left: 1px solid @cloudGray5;
      border-right: 1px solid @cloudGray5;
      border-bottom: 1px solid @cloudGray5;

      span {
        color: @cloudGray;
      }
    }
  }

  &.background-gray {
    background-color: @cloudGray5;
  }

  &.background-white {
    background-color: white;
  }

  &.background-aquamarine {
    background-color: @aquamarine1;

    &:hover {
      background-color: @aquamarine2;
    }

    &:active, &.viewing {
      background-color: @aquamarine3;
    }
  }

  &.partstat-need-action {
    border-top: 1px solid @aquamarine;
    background-color: white;
    box-shadow: 0px 0px 0px 1px @aquamarine inset;

    &:hover {
      border-top: 1px solid @middleAquamarine;
      box-shadow: 0px 0px 0px 1px @middleAquamarine inset;
      background-color: white;

      path {
        fill: @middleAquamarine;
      }
    }

    &:active, &.viewing {
      border-top: 1px solid @darkAquamarine;
      box-shadow: 0px 0px 0px 1px @darkAquamarine inset;
      background-color: white;

      path {
        fill: @darkAquamarine;
      }
    }
  }

  &.in-group {
    p {
      font-size: 12px !important;
    }

    svg {
      width: 10px;
      height: 10px;
    }

    .week-event-title-wrapper {
      svg {
        margin-bottom: -1px;
        margin-right: 3px;
      }
    }
  }


  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: @darkCarbone;

    span {
      color: @darkCarbone;
    }
  }

  .attendees {
    color: @darkCloudGray;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .attendees-week {
    display: flex;
    p {
      color: @darkCloudGray;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-child(2) {
        min-width: 25px;
        overflow:auto;
        margin-left: 5px;
        text-overflow: unset;
      }
    }
  }


  .week-event-title-wrapper {
    vertical-align: middle;
    margin: 0;

    svg {
      margin-right: 5px;
    }
  }

}
