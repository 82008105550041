@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";body {
    background-color: initial !important;
    overflow-y: auto !important;
    color: #000000 !important;
}



.printPage {
  
    font-family: 'Manrope';
    line-height: 25px;
    font-size: 15px;
    

    .printContent {
        width: 630px;
        margin: 60px;
    }

   .title {
    word-break: break-all;
    font-size: 20px;
  
    font-weight: 800;
   }

   .divider {
    margin: 19px 0 20px 0;
    width: 630px;
    height: 1px;
    background-color: #C6CAD8;

   }

   .rowWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
   }

   .printRow {
    line-height: 20px;
    word-break: break-all;
    display: flex;
    width: 100%;
  
    column-gap: 20px;

    .rowName {
        flex-basis: 150px;
        flex-shrink: 0;
        font-weight: 700;
    }

    .rowContentWrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .rowContent {
        width: 100%;
        font-weight: 500;
    }
   }
}

@media print {

    .printPage {
        .printContent {
            width: 100vw !important;
            margin: 0 !important;
            padding: 60px !important;
        }

        .divider {
            width: calc(100vw - 120px) !important;
           
        }
    }
    
  }



:global{
    @media print {

     
      * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        color-adjust: exact !important;
      }

   
    }

 
  }
  
