@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.field-errors {
  p {
    margin: 5px 0;
    color: @darkRed;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
  }
}
