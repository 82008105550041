@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.topline-wrapper {
  z-index: @toplineZIndex;
  position: sticky;
  top: 0;
}

.customTopline {
  background-color: white;

  height: 50px;
  padding: 10px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  box-shadow: 0 10px 10px -10px rgba(49, 94, 251, 0.20);
}

.mailIcon {
  svg {
    path {
      fill: #315EFB !important;
    }
  }
}

.dialog {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background: @alternativeBlue1;
  font-family: 'Manrope';

  .closeIcon {
    align-self: flex-end;
    margin: 5px;

    svg {
      path {
        fill: @ramblerBlue !important;
      }
    }
  }

  .card {
    padding: 20px;
    margin: 0 10px 10px 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 10px 10px -10px rgba(49, 94, 251, 0.20);
  }

  .profile {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    .description {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      overflow: hidden;
    }

    .displayName {
      color: @carbone;
      max-width: 1000px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .email {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: @darkCloudGray;
      font-size: 15px;
      font-weight: 500;
      height: 20px;

      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        max-width: 1000px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .copy {
        svg {
          path {
            fill: @darkCloudGray;
          }
        }
      }
    }

    .logout {
      height: 30px;
      margin-top: 20px;
      padding: 0 !important;
      align-self: flex-start;

      color: @ramblerBlue !important;
    }
  }

  .resources {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    color: @carbone;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;

    .resource {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;

      color: inherit;
      text-decoration: none;
    }
  }
}
