@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../_styles/variables';

.profile {
  display: flex;
  overflow: hidden;

  > div {
    display: unset !important;
    overflow: hidden;
  }

  .avatar {
    width: 50px;
    margin-right: 10px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCloudGray;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.name {
      color: @carbone;
    }

    &.exit {
      color: @ramblerBlue !important;
      font-weight: 700;
      user-select: none;
      cursor: pointer;
      line-height: 25px;

      &:hover {
        color: @middleBlue !important;
      }
      &:active {
        color: @darkBlue !important;
      }
    }

    &.exiting {
      user-select: none;
      cursor: pointer;
      line-height: 25px;
    }
  }

  .email {
    display: flex;
    align-items: center;
    svg {
      width: 20px !important;
      height: 20px !important;
    }

    p {
      line-height: 25px;
    }

    svg:hover path {
      fill: @middleBlue;
    }
    svg:active path {
      fill: @darkBlue;
    }
  }

  svg {
    margin-left: 5px;
    cursor: pointer;
    path {
      fill: @darkCloudGray;
    }
  }
}
