@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 10px;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Arial, serif;
  font-size: 12px;
  line-height: 20px;

  width: 240px;
  overflow: hidden;

  a {
    color: @darkCloudGray;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: @middleBlue;
    }

    &:active {
      color: @darkBlue;
    }
  }
}
