@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../../_styles/variables.less';

.addressbook__contact__avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 60px;

  background-size: cover;
  color: white;

  font-family: 'Manrope', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 50px;
}
