@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.upload-file-wrap {
  position: relative;
  width: 100%;
  height: 40px;

  background-color: @alternativeBlue1;
  border-radius: 5px;

  .link {
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
  }

  * {
    transition: background-color 200ms, color 200ms;
  }
}

.upload-file-corner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.upload-file-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  flex-direction: row;
  padding-left: 20px;

  &:hover {
    background-color: @alternativeBlue2;

    span {
      color: @middleBlue;
    }
  }

  &:active:not(:has(.upload-file-remove:hover, .upload-file-remove:active)) {
    background-color: @alternativeBlue3;

    span {
      color: @darkBlue;
    }
  }

  .nameTooltip {
    position: absolute;
    top: calc(-50% - 10px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.upload-file-remove {
  position: relative;
  padding: 5px;
  path {
    fill: @darkCloudGray;
  }

  &:hover {
    svg {
      path {
        fill: @ramblerBlue;
      }
    }
  }

  &:active {
    svg {
      path {
        fill: @darkBlue;
      }
    }
  }

  .wrap {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: white;

    cursor: pointer;
  }
}

.upload-file-content-description {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  max-width: 490px;

  padding-right: 5px;

  font-family: 'Manrope', serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  color: @darkCloudGray;
  text-decoration: none;
  white-space: nowrap;
  overflow-x: hidden;

  .name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    color: @carbone;
  }

  &:active {
    span {
      color: @darkBlue !important;
    }
  }
}

.upload-file-progress-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 19px;
  margin-right: 5px;
  width: 119px;
}
