@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.day-week-header-events {
  display: grid;
  width: calc(100% - 70px);
  position: absolute;
  left: 70px;
  top: 25px;
}

.day-week-header-events-fold {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: @darkCloudGray;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: @middleBlue;
  }

  &:active {
    color: @darkBlue;
  }
}

@media only screen and (max-width: @tablet-max) {
  .day-week-header-events {
    width: calc(100% - 40px);
    left: 40px;
  }
}
