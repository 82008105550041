@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.event-view-block {
  background: @alternativeBlue1;
  box-shadow: 0 10px 30px rgba(49, 94, 251, 0.2);
  border-radius: 15px;
  height: auto;
}

.wrapper {
  max-height: 735px;
  padding: 15px 10px 20px 20px;

  overflow-x: visible !important;
  overflow-y: scroll;
}

@-moz-document url-prefix() {
  .wrapper {
    padding: 15px 20px 20px 20px;
  }
}

.event-view-window-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  color: @carbone;
  column-gap: 5px;

  h3 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    word-break: break-word;

    padding-right: 25px;
  }

  .event-view-window-header__close-ic {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.event-strong-text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: @carbone;

  margin-top: 20px;
}

.event-text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: @carbone;
  word-break: break-word;
}

.event-view-description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: @carbone;

  overflow: hidden;
  overflow-wrap: break-word;
}

.event-info-block {
  display: flex;
  flex-direction: column;
  gap: 20px;

  background-color: white;
  border-radius: 10px;
  padding: 20px;

  p {
    margin: 0;
  }

  .event-attendee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    > div {
      display: flex;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
      flex: 1;
    }

    > svg {
      margin-left: 10px;
    }

    .name {
      display: block;
      background-color: @alternativeBlue2;
      padding: 5px 10px;
      border-radius: 25px;
      max-width: 100%;
      // width: auto;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @carbone;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .organizer {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @darkCloudGray;
      margin-left: 10px;
    }
  }

  .event-view-attachi {
    font-family: 'Manrope';

    li:hover {
      background-color: @cloudGray1;
      border-radius: 5px;

      path {
        fill: @middleBlue;
      }

      a {
        color: @middleBlue;
      }

      .attach-download-icon {
        display: inline-block;

        path {
          fill: @carbone !important;
        }

        &:hover {
          path {
            fill: @middleBlue !important;
          }
        }

        &:active {
          path {
            fill: @darkBlue !important;
          }
        }
      }
    }

    h3 {
      margin: 0;
    }

    ul {
      margin: 0;
      margin-top: 5px;
    }

    .attach-download-icon {
      display: none;
    }

    path {
      fill: @carbone;
    }

    h3 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 700;
    }

    ul {
      padding: 0;
      list-style-type: none;

      a {
        display: grid;
        grid-template-columns: 20px 2fr 20px;
        padding: 5px;
        padding-left: 0;
        font-size: 15px;
        font-weight: 500;
        justify-content: space-between;
        text-decoration: none;
        color: @carbone;

        .attach-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 5px;
        }
      }
    }

    .show-all {
      display: flex;
      width: fit-content;
      color: @ramblerBlue;
      font-weight: 600;
      line-height: 20px;
      font-size: 15px;
      font-family: 'Manrope';
      align-items: center;
      margin-top: 10px;

      svg {
        path {
          fill: @ramblerBlue;
        }
      }

      cursor: pointer;
    }

    .count {
      &:hover {
        path {
          fill: @middleBlue !important;
        }
      }
      &:active {
        path {
          fill: @darkBlue !important;
        }
      }

      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
}

.event-actions {
  margin-top: 20px;

  .buttons-right {
    column-gap: 20px;

    .print-icon:hover {
     color: #2C54E2;
    }
  }
}

.event-active-button {
  span {
    color: @darkBlue;
  }
}

.event-view-expand-description {
  color: @ramblerBlue;
  cursor: pointer;

  &:hover {
    color: @middleBlue;
  }

  &:active {
    color: @darkBlue;
  }
}

.event-view-recurrence {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: @darkCloudGray;

  margin: 0;
  margin-block-start: 5px;
}

.event-link {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  color: @ramblerBlue;
  margin-top: 10px !important;
}

.event-view-attendee-action-button-accepted {
  cursor: auto !important;
  background-color: @lightGreen !important;

  svg {
    path {
      fill: white !important
    }
  }

  &:hover, &:active {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.event-view-attendee-action-button-declined {
  cursor: auto !important;
  background-color: @lightRed !important;

  svg {
    path {
      fill: white !important
    }
  }

  &:hover, &:active {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.event-view-attendee-action-button-tentative {
  cursor: auto !important;
  background-color: @darkCloudGray !important;

  svg {
    path {
      fill: white;
    }
  }

  &:hover, &:active {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.event-view-attendee-action-button-delete {
  background-color: white !important;
  color: @carbone !important;

  &:hover {
    color: @ramblerBlue !important;
  }

  &:active {
    color: @darkBlue !important;
  }
}

.event-action-text {
  color: @ramblerBlue;
  cursor: pointer;
  user-select: none;

  svg {
    vertical-align: text-top;

    path {
      fill: @ramblerBlue;
    }
  }

  &:hover {
    color: @middleBlue;

    svg {
      path {
        fill: @middleBlue;
      }
    }
  }

  &:active {
    color: @darkBlue;

    svg {
      path {
        fill: @darkBlue;
      }
    }
  }

  &:active &.active {
    color: @darkBlue !important;

    svg {
      path {
        fill: @darkBlue !important;
      }
    }
  }
}

.mailDropdown {
  bottom: calc(100% + 5px) !important;
  top: auto !important;
  width: 202px !important;
}

@media only screen and (max-height: 800px) {
  .wrapper {
    max-height: calc(100vh - 135px);
  }
}

@media only screen and (max-width: @mobile-max) {
  .wrapper {
    max-height: 100vh;
    height: 100vh;
  }
}
