@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.header {
  padding: 10px 10px 10px 5px;
  position: sticky;
  background-color: @alternativeBlue1;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  z-index: @headerZIndex;
  min-height: 60px;


  .period-selectors-container {
    flex: 1
  }

  .header-small-buttons {
    display: flex;
    align-items: center;
    gap: 20px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        margin-left: 5px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }

      color: @carbone;

      svg {
        path {
          fill: @carbone;
        }
      }

      &.active {
        color: @ramblerBlue;
        svg {
          path {
            fill: @ramblerBlue;
          }
        }
      }

      &:hover {
        color: @middleBlue;

        svg {
          path {
            fill: @middleBlue;
          }
        }
      }

      &:active {
        color: @darkBlue;

        svg {
          path {
            fill: @darkBlue;
          }
        }
      }
    }
  }
}

.text {
  display: block;
}

@media only screen and (max-width: @tablet-max) {
  .text {
    display: none;
  }
}
