@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.notification-block {
  background-color: @carbone;
  padding: 20px 60px 20px 20px;
  border-radius: 15px;
  position: absolute;
  z-index: 11;
  font-size: 15px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      user-select: none;

      &:active, &:hover {
        color: #ffffff !important;
      }

      &:nth-child(1) {
        color: @red85;
        margin-right: 20px;
      }
      &:nth-child(2) {
        color: @darkCloudGray7
      }
    }
  }

  .notification-block-title {
    text-align: start;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @alternativeBlue1;
  }
}

