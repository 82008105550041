@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.calendar-day {
  width: 100%;
  display: grid;
  grid-template-columns: 70px repeat(1, minmax(140px, 1fr));
  margin-bottom: 10px;

  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .day-item {
    padding: 5px 10px 5px 10px;
    border-right: 1px solid @cloudGray2;
    border-bottom: 1px solid @cloudGray2;

    .day-item-background-back {
      background-color: @alternativeBlue1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .day-item-background-main-left {
      border-top-left-radius: 10px;
      background-color: white;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .day-item-background-main-right {
      border-top-right-radius: 10px;
      background-color: white;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:nth-child(2n) {
      border-right: none;
    }
    &:nth-child(1), &:nth-child(2) {
      border-radius: 0;
    }
    &:nth-child(47), &:nth-child(48) {
      border-bottom: none;
    }

    .day-item-content {
      z-index: @dayItemZIndex;
      position: relative;
    }

    .day-item-click-zone {
      display: flex;
      flex-direction: column;
      height: 100%;

      div {
        flex: 1;
      }
    }

    .day-item-text {
      color: @darkCloudGray;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin: 0;
    }

    .day-item-body {
      height: 100%;
    }
  }
}

@media only screen and (max-width: @tablet-max) {
  .calendar-day {
    grid-template-columns: 40px repeat(1, minmax(140px, 1fr));

    .day-item {
      padding: 5px !important;
    }
  }
}
