@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-delete-confirm {
  background-color: @carbone;
  padding: 20px 60px 20px 20px;
  border-radius: 15px;
  position: absolute;
  right: 0;
  top: -130px;
  width: 240px;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      cursor: pointer;
      user-select: none;

      &:nth-child(1) {
        color: @red85;
        margin-right: 20px;
      }
      &:nth-child(2) {
        color: @darkCloudGray7
      }
    }
  }

  .event-delete-confirm-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @alternativeBlue1;
    margin-bottom: 20px
  }
}
