@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.sidebar {
  height: calc(100vh - @TOPLINE_HEIGHT);
  width: 260px;
  padding: 10px;
  position: sticky;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: @sidebarZIndex;
  transition: width .3s;
  overflow-x: hidden;

  .create-event-button {
    padding: 10px;
    background-color: @mailBlue !important;

    svg path {
      fill: white;
    }

    &:hover {
      background-color: @middleBlue !important;

      svg path {
        fill: white;
      }
    }

    &:active {
      background-color: @darkBlue !important;

      svg path {
        fill: white;
      }
    }
  }


  .sidebar-page {
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font: @boldMontserratFont;
    text-transform: none;
    letter-spacing: normal;
    cursor: pointer;

    &.active {
      background: @cloudGray1;
      color: @ramblerBlue;
      border-radius: 5px;

      svg {
        path {
          fill: @ramblerBlue;
        }
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  .sidebar-icon-button {
    span {
      max-height: 20px;
    }

    &:hover {
      path {
        fill: @middleBlue;
      }
    }

    &:active {
      path {
        fill: @darkBlue;
      }
    }
  }

  .openNotification {
    background: white !important;
    border: 1px solid @aquamarine;

    filter: drop-shadow(0px 10px 10px rgba(0, 92, 86, 0.15));

    span {
      max-height: 20px;
    }

    &:hover {
      border: 1px solid @darkAquamarine;
      path {
        fill: @middleAquamarine;
      }
    }

    &:active {
      border: 1px solid @darkAquamarine;
      path {
        fill: @darkAquamarine;
      }
    }
  }

  .sidebar-divider {
    height: 1px;
    background-color: @cloudGray1;
    margin: 10px auto;
  }

  .calendar-2 {
    position: relative;
    top: -10px;

    box-shadow: none;
    transform: translateX(-10px);
    overflow: initial;

    * {
      box-sizing: content-box;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
    }
  }

  .calendar {
    width: 260px;
    margin-bottom: 20px;
    padding: 10px 7px;

    div[class*="rui-Calendar-headline"] {
      height: 20px;
    }

    div[class*="rui-Calendar-weekDay"] {
      width: 30px;
      margin-right: 5.5px;
      font-size: 12px;
    }

    button[class*="rui-Calendar-arrow"] {
      width: 20px;
      height: 20px;

      &:before {
        top: 1px;
        border-width: 0 0 2px 2px;
      }
    }

    div[class*="rui-Calendar-month"] {
      font: @boldMontserratFont;
    }

    button[class*="rui-Calendar-item"], button[class*="rui-Calendar-isActive"] {
      border-radius: 50%;
    }

    button[class*="rui-Calendar-day"], span[class*="rui-Calendar-day"] {
      width: 35px;
      height: 35px;
    }
  }

  .sidebar-collapse-button {
    background-color: white;
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 0;
    transition: .3s;

    background-color: white !important;

    > span {
      margin-bottom: -5px;
    }

    &:active, &:hover {
      background-color: white !important;
    }


    &:hover path {
      fill: @middleBlue;
    }
    &:active path {
      fill: @darkBlue;
    }
  }

  .sidebar-to-today-button {
    margin-top: 10px;
    background-color: transparent !important;
    color: @darkCarbone !important;
    justify-content: flex-start;
    padding: 0 0 0 10px;

    path {
      fill: @carbone;
    }

    &.active {
      background-color: @cloudGray1 !important;
      color: @ramblerBlue !important;
      path {
        fill: @middleBlue;
      }

      .text {
        font-weight: 600;
        margin-left: 5px;
      }
    }

    &:hover {
      background-color: @alternativeBlue3 !important;
      color: @ramblerBlue !important;
    }
    &:active {
      background-color: @alternativeBlue4 !important;
      color: @darkBlue !important;
    }

    &:hover path {
      fill: @middleBlue;
    }
    &:active path {
      fill: @darkBlue;
      color: @darkBlue !important;
    }
  }
}

@media @smallWidth {
  .sidebar {
    width: 60px;
    position: absolute;
    height: 100%;
    border-right: 1px solid rgba(100, 157, 255, .2);

    button {
      white-space: nowrap;
    }

    &.full-width {
      width: 260px;
      border-right: none;
      box-shadow: 0px 10px 30px rgba(49, 94, 251, 0.2);

      .sidebar-collapse-button {
        left: 270px;
        box-shadow: 0px 10px 30px rgba(49, 94, 251, 0.2);
      }
    }
  }
}
