@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-reccurency {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 40px;
  span {
    border: none;
  }
}

.event-create-reccurency-no-support {
  margin-top: 20px;
  padding: 15px 20px;
  background: @sunYellow;

  color: @carbone;
  font-family: Manrope, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.event-create-reccurency-block {
  border: 1px solid @cloudGray2;
  border-radius: 5px;
  padding: 19px 20px;
  margin-top: 20px;

  .event-create-reccurency-types {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      color: @darkAltBlue;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      margin-right: 20px;
      cursor: pointer;
      user-select: none;

      &.selected {
        cursor: auto;
        pointer-events: none;
        color: @carbone;
      }

      &:not(.selected):hover {
        color: @middleBlue;
      }

      &:not(.selected):active {
        color: @darkBlue;
      }
    }
  }

  .event-create-reccurency-type-block {
    margin-top: 20px;
  }

  .event-create-reccurency-type-head {
    display: flex;
    align-items: center;

    & > span {
      margin: 0 10px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @carbone;
    }
  }

  .event-create-reccurency-type-select {
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    user-select: none;

    svg {
      margin-right: 5px;
    }

    & > span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @ramblerBlue;

      path {
        fill: @ramblerBlue;
      }

      &:hover {
        color: @middleBlue;

        path {
          fill: @middleBlue;
        }
      }

      &:active {
        color: @darkBlue;

        path {
          fill: @darkBlue;
        }
      }
    }
  }

  .event-create-reccurency-type-subselect {
    margin: 20px 0 25px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .event-create-reccurency-type-subselect-head {
    display: flex;
    align-items: center;

    & > span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      color: @carbone;

      &:last-child {
        color: @ramblerBlue;
        font-weight: 500;
        margin-left: 15px;
        user-select: none;
        cursor: pointer;

        &:hover {
          color: @middleBlue;
        }
        &:active {
          color: @darkBlue;
        }
      }
    }
  }

  .event-create-reccurency-weeks-select {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 20px;

    & > div {
      display: flex;
      align-items: center;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @darkCarbone;

      span {
        border: none;
      }

      label > span:last-child {
        min-width: 25px;
      }
    }
  }

  .event-create-reccurency-months-select {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 30%;
      display: flex;
      align-items: center;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @darkCarbone;

      margin-bottom: 20px;

      &:nth-child(10), &:nth-child(11), &:nth-child(12) {
        margin-bottom: 0;
      }

      span {
        border: none;
      }
    }
  }
}

.event-create-reccurency-block-help-text {
  margin-top: 20px;
  margin-bottom: 0;
  color: @darkCloudGray;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

@media screen and (max-width: @mobile-max) {
  .event-create-reccurency-block {
    .event-create-reccurency-type-head {
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .event-create-reccurency-weeks-select {
    flex-wrap: wrap;

    & > div {
      span {
        white-space: nowrap;
      }
    }
  }
}
