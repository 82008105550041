@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.single-day-view-header-day {
  position: sticky;
  top: 0;
  padding: 15px 20px 11px 20px;
  background-color: white;
  z-index: @monthSingleDayViewHeader;
  border-bottom: 1px solid @cloudGray2;

  p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    color: @carbone;
  }
}

.single-day-modal-block-wrapper {
  background-color: white;
  width: 490px;
  box-shadow: 0px 10px 30px rgba(49, 94, 251, 0.2);
  border-radius: 15px;
  overflow: hidden;

  display: flex;
}

.single-day-modal-block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  // box-shadow: 0px 10px 30px rgba(49, 94, 251, 0.2);
  border-radius: 15px;
  background: white;
  display: inline-block;
}

.single-day-modal-block-view {
  padding: 20px;
  width: 490px;
  display: inline-block;
  overflow-y: auto;

  .go-back-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    color: @carbone;
  }
}
