@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-whole-day {
  display: flex;
  align-items: center;
  margin-top: 20px;

  // span {
  //   border: none;
  // }

  // p {
  //   font-family: 'Manrope';
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 15px;
  //   line-height: 20px;
  //   color: @darkCarbone;
  //   margin: 0;
  // }
}
