@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.fatal-error-root {
  background: #fff;
  min-height: 100vh;
  min-width: 1025px;
}

.fatal-error-icon {
  width: 470px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: auto;
    height: 100%;
  }
}

.fatal-error-textBody {
  text-align: center;
  .fatal-error-title {
    line-height: 1.13;
    font-size: 35px;
    margin-top: 0;
    font-weight: 500;
  }
  .fatal-error-description {
    font-size: 14px;
    line-height: 1.79;
  }
}

.fatal-error-btnWrapper {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 70px;
  .fatal-error-btnReload {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 1px;
    background-color: @ramblerBlue;
    font-family: Roboto, Helvetica, Arial,sans-serif;
    font-size: 11px;
    line-height: 35px;
    letter-spacing: 1.3px;
    text-align: center;
    color: #fff;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
  }
}
