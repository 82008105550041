@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-edit-loading {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: @eventCreateLoadingScreenZIndex;
}
