@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-edit-block-comparing {
  .comparing-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: @carbone;
  }

  .comparing-block-wrapper {
    overflow-x: scroll;
  }

  .comparing-block {
    display: grid;
    grid-template-columns: 380px 1fr;
    border: 1px solid @cloudGray2;
    border-radius: 10px;

    width: 1220px;
  }

  .comparing-attendees {
    border-right: 1px solid @cloudGray2;

    > div {
      border-bottom: 1px solid @cloudGray2;
    }

    > div:last-child {
      border-bottom: none;
    }

    .comparing-attendees-header {
      padding: 15px 20px;
      p {
        margin: 0;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: @darkCarbone;

        span {
          color: @darkCloudGray;
          margin-left: 5px;
        }
      }
    }
  }

  .comparing-attendee {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid @cloudGray2;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      gap: 10px;
      align-items: center;
      width: calc(100% - 25px);
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover, &.hovered {
      background-color: @cloudGray05;

      .comparing-attendee-close {
        visibility: visible;
      }
    }

    &:active {
      background-color: @cloudGray05;
    }

    .comparing-attendee-close {
      visibility: hidden;
      cursor: pointer;

      &:hover {
        path {
          fill: @middleBlue;
        }
      }

      &:active {
        path {
          fill: @darkBlue;
        }
      }
    }

    .comparing-attendee-info {
      overflow: hidden;
      cursor: pointer;
    }

    .comparing-attendee-name {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: @darkCarbone;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
    .comparing-attendee-email {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: @darkCloudGray;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  .comparing-attendee-add {
    padding: 10px 20px;
  }

  .comparing-timeline {
    position: relative;
    transition: .3s;
    height: 100%;

    &.loading {
      opacity: .5;
    }
  }

  .comparing-timeline-header {
    position: relative;
    height: 50px;
    display: flex;
    border-bottom: 1px solid @cloudGray2;
    user-select: none;
  }

  .comparing-timeline-header-time {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: @darkCloudGray;
    margin-left: 45px;
    margin-top: 15px;
    width: 15px !important;

    span {
      width: 15px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
    }

    &.black {
      color: @carbone;
    }

    &:nth-child(2) {
      margin-left: 52.5px;
    }
  }

  .comparing-timeline-grid-wrapper {
    width: 100%;
  }

  .comparing-timeline-header-left {
    position: absolute;
    height: 100%;
    right: calc(60px * 13.5);
    display: flex;
    align-items: center;
    background-color: white;

    &.disabled {
      path {
        fill: @cloudGray7;
      }
      svg {
        cursor: default;
      }
    }

    > div {
      z-index: @eventCreateEditComparingButtonsZIndex;
    }

    svg {
      margin-left: 20px;
      margin-right: -10px;
      cursor: pointer;
    }
  }

  .comparing-timeline-header-right {
    position: absolute;
    height: calc(100% - 15px);
    right: 0px;
    display: flex;
    bottom: 8px;
    align-items: center;
    background-color: white;

    &.disabled {
      path {
        fill: @cloudGray7;
      }
      svg {
        cursor: default;
      }
    }

    > div {
      z-index: @eventCreateEditComparingButtonsZIndex;
    }

    svg {
      //margin-right: 10px;
      cursor: pointer;
    }
  }

  .comparing-event-dropdown {
    max-width: 360px;
    max-height: 400px;
    position: fixed;
    padding: 10px 20px;
    overflow-y: scroll;

    .comparing-event-dropdown-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }

      .comparing-event-dropdown-item-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: @carbone;
      }
      .comparing-event-dropdown-item-time {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: @carbone;
      }
      .comparing-event-dropdown-item-location {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: @darkCloudGray;
      }
    }
  }

  .comparing-event {
    height: 50px;
    position: absolute;

    > div {
      background-color: rgba(232, 234, 240, .7);
      border-radius: 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: rgba(220, 223, 231, .7);
          .comparing-event-main-text {
            color: @middleBlue;
          }
        }

        &:active {
          background-color: rgba(209, 213, 224, .7);
          .comparing-event-main-text {
            color: @darkBlue;
          }
        }
      }

      p {
        margin: 0;
      }

      .comparing-event-main-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;

        color: @darkCarbone;
      }

      .comparing-event-secondary-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: @darkCloudGray;
      }
    }
  }

  .comparing-free-line {
    height: 5px;
    position: absolute;
    bottom: -1px;
    background-color: @lightGreen;
    left: 0;
    right: 0;
  }

  .comparing-busy-marker {
    height: 5px;
    position: absolute;
    bottom: -1px;
    background-color: white;
    border-bottom: 1px solid #E8EAF0;
  }

  .comparing-time-selector {
    background: rgba(0, 186, 173, 0.1);
    border: 1px solid rgba(0, 186, 173, 0.75);
    border-radius: 6px;
    position: absolute;
    top: 0;
    height: calc(100% + 1.25px); //Из-за border
    z-index: @eventCreateEditComparingTimeSelectorZIndex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.colliding {
      background: rgba(252, 39, 114, 0.1);
      border: 1px solid rgba(252, 39, 114, 0.75);

      .comparing-time-selector-left, .comparing-time-selector-right{
        border: 1px solid rgba(252, 39, 114, 0.75);
      }
    }

    .comparing-time-selector-left {
      background: #FFFFFF;
      border: 1px solid rgba(0, 186, 173, 0.75);
      border-radius: 4.5px;
      height: 20px;
      width: 9px;
      margin-left: -6px;
      cursor:w-resize;
    }

    .comparing-time-selector-right {
      background: #FFFFFF;
      border: 1px solid rgba(0, 186, 173, 0.75);
      border-radius: 4.5px;
      height: 20px;
      width: 9px;
      margin-right: -6px;
      cursor:e-resize;
    }
  }

  .redo-comparing-button-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .redo-comparing-button {
    padding: 0 10px;
    top: 25px;

    span {
      height: 20px;
    }
  }
}
