@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.event-create-range {
  display: flex;
  align-items: center;

  gap: 5px;
}

.start, .end {
  display: flex;
  align-items: center;
  gap: 10px;
}

.start {
  gap: 5px;
}

.splitter {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  width: 20px;
  height: 20px;
  padding-top: 1px;
}

@media only screen and (max-width: @mobile-max) {
  .event-create-range {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
  }

  .end {
    flex-direction: row-reverse;
  }
}
