@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../../../_styles/variables.less';

.mailing-contact-overview__root {
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas: 'aw dn';

  position: relative;

  padding: 10px 10px 10px 0;
  border-radius: 5px;


  &:hover {
    .mailing-contact-overview__display-name, .mailing-contact-overview__email {
      color: @ramblerBlue;
    }
  }

  &:active {
    .mailing-contact-overview__display-name, .mailing-contact-overview__email {
      color: @darkBlue;
    }
  }

  .mailing-contact-overview__avatar_wrapper {
    grid-area: aw;

    padding-right: 10px;
  }

  .mailing-contact-overview__display-name {
    grid-area: dn;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mailing-contact-overview__email {
    padding-left: 5px;
    color: @darkCloudGray;
  }
}
