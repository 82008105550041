@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.month-item-more-events {
  position: relative;
  margin-top: 3px;

  &>span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: .8px;

    color: @darkCloudGray;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: @middleBlue;
    }

    &:active {
      color: @darkBlue;
    }

    &.opened {
      color: @ramblerBlue;
    }
  }
}
