@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.week-header-item-event {
  flex: 1;
  padding: 4.5px 0 4.5px 5px;
  height: 24px;
  cursor: pointer;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: 0px 10px 10px -10px rgba(0, 92, 86, 0.4);
  z-index: @weekHeaderZIndex;
  position: relative;
  display: flex;

  &.base {
    background-color: @aquamarine;

    &:hover {
      background-color: @middleAquamarine;
    }

    &:active, &.viewing {
      background-color: @darkAquamarine;
    }
  }

  &.past, &.cancelled {
    background-color: @cloudGray05;
    box-shadow: none;
    text-decoration: line-through;
    color: @cloudGray7 !important;

    span {
      color: @cloudGray7 !important;
    }

    &:hover {
      background-color: @cloudGray2;
    }

    &:active, &.viewing {
      background-color: @cloudGray3;
      span {
        color: @cloudGray;
      }
    }
  }

  &.shadow {
    background-color: unset !important;
    border: 1px solid @cloudGray3;
    span {
      color: @cloudGray7 !important;
    }
  }

  &.partstat-need-action {
    background-color: white;
    border: 1px solid @aquamarine;
    border-radius: 5px;
    box-shadow: 0px 10px 10px -10px rgba(0, 92, 86, 0.4);
    .week-header-item-event-title {
      font-weight: 700 !important;
    }

    span {
      color: @darkCarbone;
    }

    &:hover {
      span {
        color: @middleAquamarine;
      }

      path {
        fill: @middleAquamarine;
      }
    }

    &:active, &.viewing {
      span {
        color: @darkAquamarine;
      }

      path {
        fill: @darkAquamarine;
      }
    }
  }


  &.partstat-need-action.past {
    background-color: white;
    border: 1px solid @aquamarine3;
    box-shadow: unset;

    span {
      color: @cloudGray7;
    }

    path {
      fill: @aquamarine3 !important;
    }

    &:hover {
      border: 1px solid @aquamarine4;
      span {
        color: @aquamarine4;
      }

      path {
        fill: @aquamarine4;
      }
    }

    &:active, &.viewing {
      border: 1px solid @aquamarine5;
      span {
        color: @aquamarine5;
      }

      path {
        fill: @aquamarine5;
      }
    }
  }

  &.tentative {
    path {
      fill: white;
    }

    &.past {
      path {
        fill: @cloudGray4 !important;
      }
    }
  }

  &.day .week-header-item-event-title {
    font-weight: 700;
  }

  .week-header-item-event-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;

    span {
      margin-right: 5px;
    }
  }

  .week-header-item-event-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    white-space: nowrap;
    color: white;
  }

  .week-header-item-event-location {
    color: white;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

  .week-header-item-event-attendees {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: white;
    opacity: 0.6;
  }

  .week-header-item-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;

    svg {
      width: 20px;
      height: 20px;
    }

    &.white-icon {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}
