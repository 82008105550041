@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-attendees {
  background-color: @alternativeBlue1;
  padding: 5px 5px 5px 15px;
  display: flex;
  border-radius: 5px;
  position: relative;
  transition: background-color 200ms;
  min-height: 40px;

  cursor: text;

  input {
    width: 100%;
    background: none;
    border: none;
    height: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &.error {
    background-color: @red1 !important;

    &&:hover {
      background-color: @red2;
    }
  }

  &&.focused {
    background-color: @alternativeBlue2;
  }

  &&:hover {
    background-color: @alternativeBlue2;
  }

  [class~='rui__w9cmf'] {
    display: block !important;
  }

  .attendees-icon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 5px;

    &.active {
      path {
        fill: @ramblerBlue;
      }
    }

    &:hover {
      path {
        fill: @middleBlue;
      }
    }

    &:active {
      path {
        fill: @darkBlue;
      }
    }
  }

  .attendees-dropdown {
    width: 100%;
    position: absolute;
    background-color: @alternativeBlue1;
    max-height: 240px;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0px 20px 20px -10px rgba(49, 94, 251, 0.3);
    z-index: 10;
    left: 0;

    transition: transform ease-in-out 0.1s;

    &::-webkit-scrollbar {
      position: absolute;
      height: 12px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      margin: 0 5px;
      background: @alternativeBlue6;
      border-radius: 15px;
    }


    &.opened {
      display: unset;
    }
  }

  .event-create-attendees-field {
    padding-left: 10px;
    flex: 1;
  }

  .event-create-suggester-item {
    cursor: pointer;
    padding: 10px 20px;

    p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      margin: 0;

      font-weight: 500;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    mark {
      color: @ramblerBlue;
      background-color: unset;
      font-weight: 600;
    }

    .event-create-suggester-item-name {
      color: @carbone;
      margin-right: 5px;
    }

    .event-create-suggester-item-email {
      font-weight: 500;
      color: @darkCloudGray;
    }
  }

  .event-create-suggester-item:hover {
    background-color: @alternativeBlue2;
  }

  .event-create-suggester-selected-item-remove {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      svg {
        path {
          fill: @middleBlue;
        }
      }
    }

    &:active {
      svg {
        path {
          fill: @darkBlue;
        }
      }
    }
  }

  .event-create-attendees-selected-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }

  .event-create-suggester-selected-item {
    background-color: white;
    border-radius: 25px;
    padding: 5px 5px 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @carbone;
    height: 30px;
    user-select: none;
    cursor: pointer;

    &:hover:not(:has(
    .event-create-suggester-selected-item-remove:hover,
    .event-create-suggester-selected-item-remove:active
    )) {
      color: @middleBlue;
    }

    &:active:not(:has(
       .event-create-suggester-selected-item-remove:hover,
      .event-create-suggester-selected-item-remove:active
    )) {
      color: @darkBlue;
    }

    span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 460px;
    }

    .event-create-suggester-selected-item-space {
      z-index: 1;
      width: 2px;
      height: 20px;
      margin-right: -2px;
    }

    path {
      fill: @carbone;

      &:hover {
        fill: @middleBlue;
      }

      &:active {
        fill: @darkBlue;
      }
    }

  }
}

.attendees-compare-suggest {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  color: @ramblerBlue;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: @ramblerBlue;
  }

  &:hover {
    color: @middleBlue;

    path {
      fill: @middleBlue;
    }
  }

  &:active {
    color: @darkBlue;

    path {
      fill: @darkBlue;
    }
  }
}

@media screen and (max-width: @mobile-max) {
  .event-create-attendees {
    .event-create-suggester-selected-item {
      span {
        max-width: 220px;
      }
    }
  }
}

