@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../_styles/variables.less';

.contacts-background {
  display: flex;
  flex-direction: column;

  background: #FFFFFF;
  box-shadow: 0 10px 10px -10px rgba(49, 94, 251, 0.4);
  border-radius: 10px;

  flex: 1;

  margin-bottom: 10px;
  overflow: hidden;

  .contacts-background__header {
    padding: 15px 0 19px 20px;

    border-bottom: 1px solid #E8EAF0;

    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: @carbone;
    font-family: 'Manrope', serif;
  }

  .contacts__content__wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;

    justify-content: space-between;

    overflow: scroll;
    min-width: 0;
  }

  .contacts__content {
    display: flex;
    flex-direction: column;

    flex: 1;
    padding: 20px 20px 0;
    overflow: hidden;

    min-width: 0;
  }

  .contacts__search-bar {
    position: relative;

    max-width: 615px;

    .contacts__loader {
      width: 60px;
      height: 60px;

      position: absolute;
      top: 150px;
      left: 50%;
      transform: translate3d(0 ,-50%, 0);
    }
  }

  .contacts__toggle-groups {
    margin: 20px 0 10px;
  }
}
