@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.header {
  padding: 10px 10px 10px 5px;
  position: sticky;
  background-color: @alternativeBlue1;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  z-index: @headerZIndex;
  min-height: 60px;


  .period-selectors-container {
    flex: 1
  }
}
