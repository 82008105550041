@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.period-selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    > span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      margin-left: 10px;
    }
  }
}

.togglers {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: -30px;
}

.datas {
  width: 290px;
  user-select: none;
  color: @darkCarbone;

  div:nth-child(1) {
    margin-right: 5px;
  }

  div {
    svg {
      path {
        fill: @carbone;
      }
    }
  }
}


.icons {
  display: flex;
  flex-direction: row;
  gap: 5px;

  .icon {
    height: 40px;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.1s ease-in-out;

    &.selected {
      cursor: auto;
      background-color: @alternativeBlue2 !important;
    }

    &:hover {
      background-color: @alternativeBlue3;
    }

    &:active {
      background-color: @alternativeBlue4;
    }
  }
}


@media only screen and (max-width: @tablet-max) {
  .period-selectors {
    .togglers {
      display: none;
    }
  }

  .datas {
    width: auto;
  }
}
