@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.snack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 400px;

  gap: 10px;

  padding: 10px 10px 10px 15px;
  border-radius: 5px;
  background: @carbone;

  color: white;
  font-family: Manrope, serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;

  &.success {
    background: @middleAquamarine;
  }

  &.prime {
    background: @ramblerBlue;
  }

  &.error {
    background: @darkRed;
  }

  .snack-message {
    user-select: none;
  }

  .snack-action-button {
    cursor: pointer;
    height: 20px;

    .snack-remove {
      width: 20px;
      height: 20px;

      path {
        fill: white;
      }

      &:hover {
        path {
          fill: rgba(255, 255, 255, 0.7);
        }
      }
      &:active {
        path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }

    &:hover {
      .remove {
        path {
          fill: rgba(255, 255, 255, 0.7);
        }
      }
    }
    &:active {
      .remove {
        path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
