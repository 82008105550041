@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.event-create-buttons {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 20px;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  &.scrolled {
    box-shadow: 0px -10px 10px -10px rgba(49, 94, 251, 0.40);
  }
}

.event-create-buttons-compare {
  margin-left: 10px;
  background-color: @alternativeBlue2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: @alternativeBlue3;
    path {
      fill: @middleBlue;
    }
  }
  &:active {
    background-color: @alternativeBlue4;
    path {
      fill: @darkBlue;
    }
  }
}

.event-create-button-blue {
  display: flex;
  font-weight: 600;
  line-height: 20px;
  font-size: 15px;
  font-family: 'Manrope';
  align-items: center;
  color: @ramblerBlue;
  font-style: normal;
  cursor: pointer;
  user-select: none;

  svg {
    margin-right: 5px;
    path {
      fill: @ramblerBlue;
    }
  }

  &:hover {
    color: @middleBlue;

    path {
      fill: @middleBlue;
    }
  }

  &:active {
    color: @darkBlue;

    path {
      fill: @darkBlue;
    }
  }
}

.event-create-button-blue-buttons {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;

  &.fields-hidden {
    justify-content: flex-end;
  }
}

@media screen and (max-width: @mobile-max) {
  .event-create-button-blue {
    span {
      display: none;
    }
  }
  .event-create-button-blue-buttons {
    &.fields-hidden {
      justify-content: flex-start;
    }
  }
}
