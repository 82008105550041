@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.new-event__participants {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: @darkCloudGray;

  &.cancelled {
    color: @cloudGray7;
  }

  .participants__few-names {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
