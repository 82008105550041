@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.floating-modal {
  position: fixed;
  z-index: @floatingModalZIndex;
  visibility: hidden;

  &.opened {
    visibility: visible;
  }
}

.floating-modal-background {
  z-index: @floatingModalBackgroundZIndex;
  background-color: @carbone;
  opacity: .95;
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;


  &.opened {
    display: block !important;
  }
}
