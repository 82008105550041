@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.avatar-comp {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background-size: cover;
  color: white;

  font-family: 'Manrope', serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
