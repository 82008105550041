@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import "./variables";
@import "./fonts";

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #262626;
  background-color: @alternativeBlue1;
  overflow-y: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flex {
    display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.custom-scroll-bar {
  &::-webkit-scrollbar {
    border: none;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    box-shadow: none;
    background-color: @alternativeBlue6;
    border-radius: 5px;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.text-disabled {
  color: @cloudGray7;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

a {
  text-decoration: none;
  color: @ramblerBlue;
}
