@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";.uploads-hidden {
  overflow: hidden;
}

.uploads {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  background-color: rgb(255, 255, 255);
  flex: 0 0 auto;

  .head {
    margin-top: 10px;
    position: relative;
    font-size: 15px;
    line-height: 20px;
    color: @ramblerBlue;
    font-weight: 600;

    .heading {
      font-weight: 600;
      font-size: 15px;
      font-family: 'Manrope';
      line-height: 20px;

      color: @ramblerBlue;
      height: 20px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: @ramblerBlue;
        }
      }

      .button {
        // svg {
        //   fill: @mailBlue;
        //   width: 20px;
        //   height: 20px;
        // }
        display: inline-block;
      }

      .count {

      }
    }

    &:hover {
      span {
        color: @middleBlue;
      }

      svg {
        path {
          fill: @middleBlue;
        }
      }
    }

    &:active {
      span {
        color: @darkBlue;
      }

      svg {
        path {
          fill: @darkBlue;
        }
      }
    }

    // :hover {
    //   span {
    //     color: @carbone !important;
    //   }
    //   svg {
    //     path {
    //       fill: @carbone !important;
    //     }
    //   }
    // }

    .size {
      margin-left: 5px;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #777E98;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -20px 0 0;
    transition: max-height 200ms ease-out, opacity 200ms ease-out;

    .files {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      overflow-x: hidden;
    }

    .item {
      height: 35px;
      margin: 0 20px 15px 0;
      display: inline-block;
      background-color: rgb(143, 164, 161);
      position: relative;

      .fileIcon {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 0 0;

        svg {
          width: 15px;
          height: 20px;
          fill: var(--shaded);
          opacity: 0.9;
        }
      }

      .content {
        display: inline-block;
        width: 126px;
      }

      .name {
        width: 100%;
        font-family: 'Manrope';
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        padding: 1px 0 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        color: var(--dark);
      }

      .type {
        position: absolute;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.82;
        letter-spacing: 1px;
        text-transform: uppercase;

        .attachSize {
          font-size: 11px;
          letter-spacing: 1px;
          line-height: 1.82;
          font-weight: normal;
          opacity: 0.9;
          text-transform: uppercase;
          color: var(--shaded);
          margin: 0 0 0 5px;
        }
      }

      .size {
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 1.2;
        text-transform: uppercase;
        color: var(--darkCloudGray);

        position: absolute;
        left: 55px;
        bottom: 5px;
      }
    }

    .itemAdded {
      margin: 0 38px 15px 0;
    }
  }

  .imagesPreviewBlock {
    width: 100%;
    padding-top: 10px;

    .images {
      margin-left: -10px;
      margin-top: -10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
