@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.calendar-week {
  width: 100%;
  display: grid;
  grid-template-columns: 70px repeat(7, minmax(50px, 1fr));
  grid-template-rows: repeat(24, minmax(89px, 1fr));
  margin-bottom: 10px;

  background-color: white;
  border-radius: 0 0 10px 10px;

  .week-item {
    padding: 5px 10px 5px 10px;
    border-right: 1px solid @cloudGray2;
    border-bottom: 1px solid @cloudGray2;

    .week-item-background-back {
      background-color: @alternativeBlue1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .week-item-background-main-left {
      border-top-left-radius: 10px;
      background-color: white;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .week-item-background-main-right {
      border-top-right-radius: 10px;
      background-color: white;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:nth-child(8n) {
      border-right: none;
    }
    &:nth-child(185), &:nth-child(186), &:nth-child(187), &:nth-child(188), &:nth-child(189), &:nth-child(190), &:nth-child(191), &:nth-child(192) {
      border-bottom: none;
    }

    &.active {
      background-color: @cloudGray05;
      .week-item-header {
        span {
          color: @ramblerBlue !important;
        }
      }
    }

    &.past {
      .week-item-header {
        span {
          &:nth-child(2) {
            color: @cloudGray7
          }
        }
      }
    }

    .week-item-content {
      // overflow: hidden;
      z-index: @weekItemZIndex;
      position: relative;
    }

    .week-item-body {
      height: 100%;
    }

    .week-item-text {
      color: @darkCloudGray;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin: 0;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
    }
  }
}
