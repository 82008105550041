@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.calendar-day-week-header {
  position: sticky;
  width: 100%;
  display: grid;
  z-index: @dayWeekHeaderZIndex;
  background-color: @alternativeBlue1;

  &>.day-week-header-item {
    &:first-of-type {
      border-top-left-radius: 10px;
    }
  }

  .day-week-header-item {
    background-color: white;
    padding: 5px 10px 5px 10px;
    border-right: 1px solid @cloudGray2;
    border-bottom: 1px solid @cloudGray2;

    &.active {
      background-color: @cloudGray05;
    }

    .day-week-header-item-text {
      color: @darkCloudGray;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-top: 20px;
    }

    .day-week-header-item-button {
      cursor: pointer;
      height: 20px;
    }

    .day-week-header-item-header {
      display: flex;
      justify-content: space-between;
      height: 15px;

      p {
        color: @darkCloudGray;
        font-family: 'Manrope';
        font-style: normal;
        font-size: 13px;
        line-height: 15px;
        margin: 0;

        span:nth-child(1) {
          font-weight: 700;
        }
        span:nth-child(2) {
          margin-left: 5px;
          font-weight: 400;
        }
      }
    }
  }
}

@media only screen and (max-width: @tablet-max) {
  .calendar-day-week-header {
    .day-week-header-item {
      padding: 5px;
    }
  }
}
