@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.date-input {
  position: relative;

  .active {
    button {
      display: flex !important;
    }
  }

  .default {
    button {
      color: @darkCloudGray;
    }
  }
}

.date-input-dropdown {
  position: fixed;
  z-index: @floatingModalZIndex;
}
