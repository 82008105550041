@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../_styles/variables.less';

.toggle-groups__root {
  display: flex;
  flex-direction: row;
  gap: 20px;

  span {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    color: @darkAltBlue;

    cursor: pointer;

    transition: color 0.1s ease-in-out;

    &.active {
      color: @carbone !important;
    }

    &:hover {
      color: @middleBlue;
    }

    &:active {
      color: @darkBlue;
    }
  }
}
