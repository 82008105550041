@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
@import '../../../../../_styles/variables.less';

.mailing-contacts-list__root {
  display: flex;
  flex-direction: column;

  min-height: 100px;

  .mailing-contacts__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mailing-contacts-list__content {
    display: flex;
    flex-direction: column;
    max-width: 615px;

    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: @darkCarbone;

    margin-bottom: 10px;

    .mailing-contacts-list__count {
      color: @darkCloudGray;

      margin-bottom: 10px;
    }
  }
}
