@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.new-event__conflicts {
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 10px;
  background: @sunYellow;

  .new-event__conflicts-header {
    user-select: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: @carbone;

    .new-event__conflicts-toggle {
      color: @ramblerBlue;
      cursor: pointer;
    }
  }

  .new-event__conflicts__list-hide {
    // For correct participants rendering
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .new-event__conflicts__list {
    height: auto;
    overflow: auto;
    opacity: 1;
    pointer-events: auto;
  }

  .new-event__conflict {
    margin-top: 10px;

    &:last-child {
      padding-bottom: 5px;
    }
  }
}
