@import "/builds/pcalendar/ramail-calendar/src//_styles/variables.less";@import "/builds/pcalendar/ramail-calendar/src//_styles/fonts.less";
.event-create-window {
  position: absolute;
  overflow-x: visible !important;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(49, 94, 251, 0.2);
  border-radius: 15px;
  z-index: @eventCreateWindowZIndex;
  padding: 15px 20px 20px 20px;
  width: 600px;
  visibility: hidden;

  &.opened {
    visibility: visible;
  }
}

.event-create-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
  }
}
